import * as React from "react";
import Formulaire from "./components/formulaire.js/formulaire";
import Fournisseur from "./components/fournisseur/fournisseur";
import Description from "./components/description/description";
import "../../styles/app.css";
import Temoignages from "./components/temoignages/temoignages";
import Chantier from "./components/chantier/chantier";
import MainImage from "./components/mainImage/mainImage";
import Footer from "./components/footer/footer";
import Info from "./components/info/info";
import Seo from './components/seo/seo'
import Video from "./components/video/video";
import Promotion from "./components/promotion/promotion";
import { usePromo } from "../hooks/usePromo";


const IndexPage = () => {
  const {isPromo, month} = usePromo()
  

  return (
    <div>
    {isPromo && <Promotion month={month}/>}      
      <MainImage />
      <Fournisseur />                      
      <Description />
      <Fournisseur showroom />
      <Video />      
      <Chantier />
      <Temoignages />      
      <Formulaire />
      <Info />     
      <Footer />          
    </div>
  );
};

export default IndexPage;

export const Head = () => <Seo />
